import $ from 'jquery';

export function initSpoiler() {
  $('.faq-question').each(function(){
    if (!$(this).hasClass('-processed')) {
      $(this).addClass('-processed');

      $(this).on('click.pxtrigger', function(e){
        if( $(e.target).closest('a').length === 0 )
          $(this).toggleClass('-open');
        else{
          localStorage.setItem('scollY', $(window).scrollTop());
          localStorage.setItem('open-faq-index', $(this).index());
        }
      });
    }
  });

  // Open the last used FAQ section
  setTimeout(function(){
    if( localStorage.getItem('scollY') ){
      $('html, body').animate({
        scrollTop: localStorage.getItem('scollY')
      }, function(){
        if( localStorage.getItem('open-faq-index') ){
          $('.faq-question').eq( localStorage.getItem('open-faq-index') ).addClass('-open');
          localStorage.removeItem('open-faq-index');
        }
        localStorage.removeItem('scollY');
      });
    }
  }, 1800);
}
