export function RudderStack() {
  // Read the UTM-params
  let utm_query = decodeURIComponent( window.location.search.substring(1) );
  let utm_params = utm_query.split('&');
  let utm_params_parsed = {};

  if (utm_query) {
    utm_params.forEach(function(param){
      let name = param.split('=')[0];
      let value = param.split('=')[1];

      utm_params_parsed[name] = value;
    });

    window.rudderanalytics.track("PWS UTM parameters", utm_params_parsed, () => {});
  }
}
