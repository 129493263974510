import $ from 'jquery'
import 'jquery.easing'
import 'jquery.cycle2'

export function about() {
  if (window.timeoutIds) {
    window.timeoutIds.forEach(clearTimeout);
  }
  window.timeoutIds = [];

  let pageInitTimeout,
    showImagesTimeout;

  /**
   * This must be a singleton file
   */
  var isAboutInitialized = false;
  if (isAboutInitialized) {
    return;
  }
  isAboutInitialized = true;
  pageInitTimeout = setTimeout(function () {
    pageInitTimeout = null;
    initAbout();
  }, 100);
  window.timeoutIds.push(pageInitTimeout);
  /**
   * Singleton end
   */

  function initAbout() {
    $('.cycle-slideshow-about').cycle();
  }

  // Show images
  showImagesTimeout = setTimeout(function () {
    showImagesTimeout = null;
    $('.photo-montage__image').addClass('-show');
  }, 300);
  window.timeoutIds.push(showImagesTimeout);
}
