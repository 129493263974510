import $ from "jquery";
import { isBrowser } from "../helpers/general-helper";

export function cssImagesLazyLoader() {
  if (isBrowser) {
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    function lazyLoading() {
      if ($('[data-loading="lazy"]').length > 0) {
        $('[data-loading="lazy"]').each(function() {
          let $e = $(this);
          let backgroundImage = $e.attr('data-lazy-background-image');

          if ($e.isInViewport()) {
            $e.css({
              'background-image': backgroundImage
            });
          }
        });
      }
    }

    lazyLoading();

    $(window).on('resize scroll', function() {
      lazyLoading();
    });
  }
}
