import $ from 'jquery'
import {navigate} from "gatsby"

export function sammpsFeatures2 () {
  // Redirect to default language page if there is no requested language
  const path = window.location.pathname;
  const pathLength = path.length;
  const pathEnd = path.slice(pathLength - 1, pathLength) === '/' ? pathLength - 1 : pathLength;
  const language = path.slice('/sammps2/'.length, pathEnd);
  const languages = [
    'fr',
    'de',
    'it',
    'pt',
    'ru',
    'es',
    'uk',
    'en'
  ];
  if (!languages.includes(language) && !path.includes('sammps-preview')) navigate('/sammps2/');

  let $tabs = $('.sammps-features__sidebar__tabs__item');
  let $contents = $('.sammps-features__main__item');

  $tabs.eq(0).addClass('-active');
  $contents.eq(0).addClass('-active');

  $tabs.each(function() {
    let $tab = $(this);
    let $content = $contents.eq($tab.index());

    $tab.on('click', function() {
      $tabs.removeClass('-active');
      $contents.removeClass('-active');
      $tab.addClass('-active');
      $content.addClass('-active');
    });
  });
}
