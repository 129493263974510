import $ from 'jquery'
import Bowser from 'bowser'

export function smartalbumsTry () {
	var redirectForm = $('.select-platform-form');
	var redirectSubmit = redirectForm.find('.select-platform-redirect');
	var redirectFeedback = redirectForm.find('.feedback');
	redirectSubmit.click(redirectToSignup);

	function redirectToSignup (e) {
		redirectFeedback.hide();

		var platformType = Bowser.parse(window.navigator.userAgent).platform.type;
		var isTablet = platformType === 'tablet';
		var isPhone = platformType === 'mobile';
		var isMobile = (isTablet || isPhone);

		var selectedOS = serializeObject(redirectForm).platform;
		if(typeof selectedOS === 'undefined') {
			redirectFeedback.show();
			return false;
		}

    let lang = redirectSubmit.attr('data-lang');
    let redirectURL = '';

    if (lang === 'en-us') {
      redirectURL = (isMobile) ? `/mobile-trial-download-sign-up/${selectedOS}/` : `/trial_download_sign_up_follow/${selectedOS}`;
    } else {
      redirectURL = (isMobile) ? `/${lang}/mobile-trial-download-sign-up/${selectedOS}/` : `/${lang}/trial_download_sign_up_follow/${selectedOS}`;
    }

		window.location.href = redirectURL;

		return false;
	}

	function serializeObject (form) {
	   var o = {};
	   var a = form.serializeArray();
	   $.each(a, function() {
		   if (o[this.name]) {
			   if (!o[this.name].push) {
				   o[this.name] = [o[this.name]];
			   }
			   o[this.name].push(this.value || '');
		   } else {
			   o[this.name] = this.value || '';
		   }
	   });
	   return o;
	}
}
