import $ from 'jquery'

export function scrollSlideDown () {

  $(document).ready(function(){

    // Carousel on new SA page
    function initScrollWaypoints() {

      if(
        $('.smartslides-overview').length ||
        $('.smartslides-examples').length
      ){

        // Setup waypoint watch
        $('.waypoint').each(function(i) {
          var
              self = $(this),
              reveal_offset = self.attr('data-offset') ? self.attr('data-offset') + '%' : '90%';

          self.waypoint({
            handler: function(direction) {
              self.addClass('waypoint-reveal');
              self.waypoint('destroy');// stuff has fired once, now let's destroy it
            },
            offset: reveal_offset
          });
        });

      }

    }
    initScrollWaypoints();

  });

}
