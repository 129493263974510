import $ from 'jquery'

export function initSmartSlidesPricing() {
    // Init annual/monthly plans toggle
    var $toggle = $('.btn-toggle');

    $toggle.on('click.pxtrigger', function(){
        displayPlans(this.id);
    });

    function displayPlans(category) {
        var $targetId =  $('#' + category);
        var $subtextId = $('#' + category + 'Subtext');
        $('.pricing-container .active').removeClass('active');
        $targetId.addClass('active');
        $subtextId.addClass('active');
        if (category === 'annual') {
            $('.pricing-container').removeClass('monthly');
            $('.pricing-container').addClass('annual');
        } else {
            $('.pricing-container').removeClass('annual');
            $('.pricing-container').addClass('monthly');
        }
    }
    displayPlans('annual');

    const $yearly = $('[data-action="yearly"]');
    const $monthly = $('[data-action="monthly"]');
    const $switch = $('[data-action="switch"]');
    const $container = $('.pricing-container');
    const $note_yearly = $('.smartslides-pricing__note__item[data-type="yearly"]');
    const $note_monthly = $('.smartslides-pricing__note__item[data-type="monthly"]');

    function switchToYearly() {
      if (!$container.hasClass('-loading')) {
        $monthly.removeClass('-active');
        $yearly.addClass('-active');
        $switch.removeClass('-monthly').addClass('-yearly');
        $container.removeClass('monthly').addClass('annual');
        $note_yearly.addClass('-visible');
        $note_monthly.removeClass('-visible');
      }
    }

    function switchToMonthly() {
      if (!$container.hasClass('-loading')) {
        $yearly.removeClass('-active');
        $monthly.addClass('-active');
        $switch.removeClass('-yearly').addClass('-monthly');
        $container.removeClass('annual').addClass('monthly');
        $note_monthly.addClass('-visible');
        $note_yearly.removeClass('-visible');
      }
    }

    $yearly.on('click.pxtrigger', function() {
      switchToYearly();
    });

    $monthly.on('click.pxtrigger', function() {
      switchToMonthly();
    });

    $switch.on('click.pxtrigger', function() {
      if ($container.hasClass('annual'))
        switchToMonthly();
      else
        switchToYearly();
    });
}
