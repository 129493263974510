import $ from 'jquery';

export function SmartSlidesOverview() {
  if (window.intervalIds) {
    window.intervalIds.forEach(clearInterval);
  }
  window.intervalIds = [];

  // Hero section animations
  if ($('.smartslides__hero').length > 0) {
    $('.smartslides__hero').addClass('-animated');
  }

  // Slideshows
  let sliderInterval;
  let $current;
  let $next;

  if ($('.smartslides__slideshow-component').length > 0) {
    $('.smartslides__slideshow-component').each(function() {
      let $slider = $(this);

      sliderInterval = setInterval(function() {
        $current = $slider.find('.smartslides__slideshow-component__item.-active');

        if ($slider.find('.smartslides__slideshow-component__item.-active').next('.smartslides__slideshow-component__item').length > 0) {
          $next = $slider.find('.smartslides__slideshow-component__item.-active').next('.smartslides__slideshow-component__item');
        } else {
          $next = $slider.find('.smartslides__slideshow-component__item:first');
        }

        $next.addClass('-active');
        $current.removeClass('-active');
      }, 4000);
      window.intervalIds.push(sliderInterval);
    });
  }
}
