import $ from 'jquery'

export function cloudProofing() {

  let itemAnimationTimeouts = new Map();

  // Parallax
  function heroParallax() {
    if ($(window).width() >= 960) {
      // controlable elems
      var openingPanel = $('.hero .overlay');
      var openingPanelInner = $('.hero .bg');

      var x = $(window).scrollTop();

      // parallax background
      if ($(window).scrollTop() <= $(window).height()) {
        openingPanelInner.css({
          'transform': 'translateY(' + x / 7 + 'px)'
        });
        openingPanel.css({
          'transform': 'translateY(' + x / 4 + 'px)',
          'opacity': 1 - (x / 400)
        });

        $('.parallax-2').css({
          'transform': 'translateY(' + x / 2 + 'px)'
        });
        $('.parallax-3').css({
          'transform': 'translateY(' + x / 3 + 'px)'
        });
        $('.parallax-4').css({
          'transform': 'translateY(' + x / 4 + 'px)'
        });
        $('.parallax-5').css({
          'transform': 'translateY(' + x / 5 + 'px)'
        });
        $('.parallax-6').css({
          'transform': 'translateY(' + x / 6 + 'px)'
        });
        $('.parallax-7').css({
          'transform': 'translateY(' + x / 7 + 'px)'
        });
        $('.parallax-8').css({
          'transform': 'translateY(' + x / 8 + 'px)'
        });
        $('.parallax-9').css({
          'transform': 'translateY(' + x / 9 + 'px)'
        });
        $('.parallax-10').css({
          'transform': 'translateY(' + x / 10 + 'px)'
        });
      }
    }
  }
  function sectionParallax() {
    if ($(window).width() >= 960) {

      $('.parallax-panel').each(function () {
        var offsetTop = $(this).offset().top; // How far from the top the panel is
        var inview = $(window).scrollTop() + $(window).height(); // the measurement of when something enters view

        var pos_range = inview - offsetTop - 500;
        var x = pos_range;

        if (inview >= offsetTop) {
          $(this).find('.parallax-overlay').css({
            'transform': 'translateY(' + x / 6 + 'px)'
          });
          $(this).find('.parallax-bg').css({
            'transform': 'translateY(' + x / 12 + 'px)'
          });
        }
      });

    } else {
      $('.parallax-overlay, .parallax-bg').css({ 'transform': 'translateY(0)' });
    }
  }

  heroParallax();
  sectionParallax();

  $(window).on('scroll.pxtrigger', function () {
    heroParallax();
    sectionParallax();
  });

  $('.step-in-trigger').each(function () {

    var self = $(this);
    self.addClass('pre-animate').find('.step-in').each(function (i) {
      var el = $(this);

      itemAnimationTimeouts.set(i, setTimeout(function () {
        itemAnimationTimeouts.delete(i);
        el.addClass('animate');
      }, i * 150));
    });
  });
}
