import $ from 'jquery';

export function CalculatorPage() {
  if ($(window).width() >= 1280) {
    let $info = $('.calculator-page__info.-fixed');
    let scrollTop = $(window).scrollTop();
    let top = $info.height() + 48;

    function setInfoPosition() {
      scrollTop = $(window).scrollTop();

      if (scrollTop >= top) {
        $info.addClass('-stop-fixed');
      } else {
        $info.removeClass('-stop-fixed');
      }
    }

    $(window).on('scroll', function() {
      setInfoPosition();
    });
  }
}
