import $ from 'jquery'

export function initSmartAlbumsPricing() {

  // Show tooltips on tap
  $('body').on('tap.pxtrigger', '.tooltip', function () {
      $('.tooltip.display-tooltip').removeClass('display-tooltip');
      $(this).addClass('display-tooltip');
  });
  // Hide tooltips on tap outside
  $('body').on('tap.pxtrigger', function (e) {
      var $el = $(e.target);

      if(!$el.is('.tooltip')
          && ($el.parents('.tooltip').length === 0))
      {
          $('.tooltip.display-tooltip')
              .removeClass('display-tooltip');
      }
  });
  // Hide tooltips on scroll
  $(window).on('scroll.pxtrigger', function (e) {
      $('.tooltip.display-tooltip').removeClass('display-tooltip');
  });

}
