import $ from 'jquery'
import Bowser from 'bowser'

export function SmartAlbumsOverview() {
  if (window.intervalIds) {
    window.intervalIds.forEach(clearInterval);
  }
  window.intervalIds = [];

  if (window.timeoutIds) {
    window.timeoutIds.forEach(clearTimeout);
  }
  window.timeoutIds = [];

  let videoLoadWaitingInterval,
    videoLoadWaitingTimeout;

  let PWS;
  PWS = typeof (PWS) === 'undefined' ? {} : PWS;
  PWS.isTabletScreenSize = document.documentElement.clientWidth < 1280 && document.documentElement.clientWidth >= 768;
  PWS.isMobileScreenSize = document.documentElement.clientWidth < 768;


  // Periods removing on desktop
  $('.sa-overview__hero__footer__text').each(function () {

    var
      $e = $(this),
      html = $(this).html();

    $e.html(html.replace(/\./g, '<span class="sa-overview__hero__footer__text__period">.</span>'));

  });


  // Hero video positioning
  var
    $video = $('#video'),
    $wrapper = $video.closest('.sa-overview__hero__video-wrapper');

  function hidderPositioning() {

    if ($wrapper.hasClass('-visible'))
      $wrapper.removeClass('-visible');

    videoLoadWaitingInterval = setInterval(function () {

      videoLoadWaitingTimeout = setTimeout(function () {
        videoLoadWaitingTimeout = null;

        if (document.getElementById('video').readyState === 4) {
          $video.css('width', $video.innerHeight() * 1.49);

          $wrapper.css('height', $video.height() - 2);// Windows Chrome & Edge fix

          $wrapper.addClass('-visible');

          clearInterval(videoLoadWaitingInterval);
          videoLoadWaitingInterval = null;
        }

        clearTimeout(videoLoadWaitingTimeout);
      }, 100);

    }, 100);

  }
  hidderPositioning();

  $(window).on('resize.pxtrigger', function () {
    if (!PWS.isTabletScreenSize && !PWS.isMobileScreenSize)
      hidderPositioning();
  });

  window.addEventListener('orientationchange', function (event) {
    hidderPositioning();
  });


  // Sticky footer
  PWS.platformType = Bowser.parse(window.navigator.userAgent).platform.type;
  PWS.isPhone = PWS.platformType === 'mobile';

  var
    $sticky_footer,
    $navigation,
    header_height,
    scroll_top = 0,
    breakpoint = 0,
    desktop_caption = (!PWS.isPhone) ? 20 : 0;

  function stickyFooter() {

    if ($('.sa-overview__testimonials').length) {

      $sticky_footer = $('.sa-overview__navigation');
      $navigation = $('.sa-overview__navigation__element.-white');
      header_height = ($('.banner2023').length > 0) ? $('.banner2023').height() + $('.internal-sub-header').height() : $('.internal-sub-header').height();

      scroll_top = $(window).scrollTop();
      breakpoint = $('.sa-overview__testimonials').offset().top - header_height - $(window).height() - desktop_caption + $sticky_footer.innerHeight();

      // Switch sticky footer to white on scroll
      if (scroll_top > 0 && !$navigation.hasClass('-visible'))
        $navigation.addClass('-visible');

      else if (scroll_top === 0 && $navigation.hasClass('-visible'))
        $navigation.removeClass('-visible');

      // Position of the sticky footer
      if (scroll_top >= breakpoint)
        $sticky_footer.addClass('-relative-to-testimonials');

      else
        $sticky_footer.removeClass('-relative-to-testimonials');

    }

  }

  $('.sa-overview__navigation').removeClass('-visible');
  stickyFooter();
  setTimeout(function () {
    $('.sa-overview__navigation').addClass('-visible');
  }, 250);

  $(window).on('scroll.pxtrigger', function () {
    stickyFooter();
  });


  // Scroll navigation
  var
    $nav_items = $('.sa-overview__navigation__element__item'),
    $nav_sections = $('.sa-overview__section');

  $nav_items.each(function () {

    var
      $e = $(this),
      to = $e.data('to'),
      $nav_section = $('.sa-overview__section[data-id="' + to + '"]'),
      top = 0;

    $e.click(function () {// Scroll on click

      if (PWS.isTabletScreenSize)
        top = $nav_section.offset().top - 60;
      else if (PWS.isMobileScreenSize)
        top = $nav_section.offset().top - 60;
      else
        top = $nav_section.offset().top;

      $('html, body').animate({
        scrollTop: top
      }, 1500);

    });

  });

  // Highlight the Sticky Footer links
  function stickyFooterHighlight() {

    if ($('.sa-overview__navigation__element').length) {

      scroll_top = $(window).scrollTop() + $(window).height() - $navigation.height();

      $nav_sections.each(function () {

        var
          $e = $(this),
          id = $e.data('id'),
          offset_top = $e.offset().top,
          $nav_item = $('.sa-overview__navigation__element__item[data-to="' + id + '"]');

        if (scroll_top >= offset_top) {
          $nav_items.removeClass('-active');
          $nav_item.addClass('-active');
        }

      });

      if (scroll_top >= $('.sa-overview__testimonials').offset().top || scroll_top <= $('.sa-overview__section:first').offset().top)
        $nav_items.removeClass('-active');

    }

  }

  stickyFooterHighlight();

  $(window).on('scroll.pxtrigger', function () {
    stickyFooterHighlight();
  });


  if (!PWS.isTabletScreenSize && !PWS.isMobileScreenSize) {


    // Video Testimonials animation
    var
      $videos,
      $items,
      item_height = 0,
      videos_start = 0,
      videos_end = 0,
      progress = 0,
      sub_progress = 0,
      shift_0 = 0,
      shift_1 = 0,
      shift_2 = 0,
      last_scroll_top = 0;

    function videoTestimonials() {

      if ($('.sa-overview__footer__videos').length) {

        $videos = $('.sa-overview__footer__videos');
        $items = $('.sa-overview__footer__videos__item');
        item_height = $videos.find('.sa-overview__footer__videos__item:first').height();
        videos_start = $videos.offset().top + $(window).height() - $('.page-header').height() - $('.internal-sub-header').height();
        videos_end = $('.sa-overview__footer__get-started').offset().top - $('.page-header').height();
        shift_0 = $items.eq(0).find('.sa-overview__footer__videos__item__testimonial').position().top - $('.internal-sub-header').height() - 20;
        shift_1 = $items.eq(1).find('.sa-overview__footer__videos__item__testimonial').position().top - $('.internal-sub-header').height() - 20;
        shift_2 = ($items.eq(2).height() - $items.eq(2).find('.sa-overview__footer__videos__item__testimonial').height()) - ($items.eq(2).height() - $items.eq(2).find('.sa-overview__footer__videos__item__video').height()) / 2 - 20;

        progress = (scroll_top - videos_start + item_height) / item_height - 1;

        $videos.addClass('-in-viewport');

        // Video Testimonials in viewport
        if (scroll_top >= videos_start && scroll_top < videos_end) {

          if (scroll_top > last_scroll_top) {// Scrolling down

            // Show and hide the sections
            if (progress < 0.7) {// 1
              $items.eq(0).removeClass('-hidden');
              if (progress < 0.5) {
                $items.eq(1).addClass('-hidden');
                $items.eq(2).addClass('-hidden');
              }
            }

            else if (progress >= 0.7 && progress < 1.4) {// 2
              $items.eq(1).removeClass('-hidden');
              if (progress > 0.9 && progress < 1.2) {
                //$items.eq(0).addClass('-hidden');
                $items.eq(2).addClass('-hidden');
              }
            }

            else if (progress >= 1.4) {// 3
              $items.eq(2).removeClass('-hidden');
              if (progress > 1.6) {
                $items.eq(0).addClass('-hidden');
                //$items.eq(1).addClass('-hidden');
              }
            }

          }

          else {// Scrolling up

            // Show and hide the sections
            if (progress <= 1.45 && progress > 0.75) {
              $items.eq(2).addClass('-hidden');
            }
            else if (progress <= 0.75) {
              $items.eq(1).addClass('-hidden');
            }

          }

          // Opacity and transition for the texts
          if (progress < 0.8) {// 1

            sub_progress = progress / 0.8;

            $items.eq(0).find('.sa-overview__footer__videos__item__testimonial').css({
              'transform': 'translate(0, -' + (shift_0 * sub_progress) + 'px)'
            });

            if (sub_progress >= 0.5) {
              $items.eq(0).find('.sa-overview__footer__videos__item__testimonial').css({
                'opacity': 1 - ((sub_progress - 0.5) * 2)
              });
            }

          }

          if (progress >= 0.6 && progress < 1.5) {// 2

            sub_progress = (progress - 0.7) / 0.8;

            $items.eq(1).find('.sa-overview__footer__videos__item__testimonial').css({
              'transform': 'translate(0, -' + (shift_1 * sub_progress) + 'px)'
            });

            if (sub_progress >= 0.5) {
              $items.eq(1).find('.sa-overview__footer__videos__item__testimonial').css({
                'opacity': 1 - ((sub_progress - 0.5) * 2)
              });
            }
            else {

              $items.eq(1).find('.sa-overview__footer__videos__item__testimonial').css({
                'opacity': sub_progress * 2
              });

              if (sub_progress >= 0.15) {
                $items.eq(0).find('.sa-overview__footer__videos__item__testimonial').css({
                  'opacity': 0
                });
              }

            }

          }

          if (progress >= 1.3) {// 3

            sub_progress = (progress - 1.4) / 0.6;

            $items.eq(2).find('.sa-overview__footer__videos__item__testimonial').css({
              'opacity': sub_progress,
              'transform': 'translate(0, -' + (shift_2 * sub_progress) + 'px)'
            });

            if (sub_progress >= 0.15) {
              $items.eq(1).find('.sa-overview__footer__videos__item__testimonial').css({
                'opacity': 0
              });
            }

          }

          last_scroll_top = $(window).scrollTop() + $(window).height() - $navigation.height();

        }

        // Footer in viewport
        else if (scroll_top >= videos_start && scroll_top >= videos_end) {
          $videos.removeClass('-in-viewport');
          $items.eq(0).removeClass('-hidden');
        }
        // Nothing in viewport
        else {
          $videos.removeClass('-in-viewport');
          $items.eq(0).addClass('-hidden');
        }

        // First section visible in a third part
        if (scroll_top >= videos_start - $(window).height() / 2) {
          $items.eq(0).removeClass('-hidden');
        }
        // Nothing in viewport
        else {
          $items.eq(0).addClass('-hidden');
        }

      }

    }

    videoTestimonials();

    $(window).on('scroll.pxtrigger', function () {
      videoTestimonials();
    });


    // Content scrollable sections
    var
      $sections,
      $testimonials,
      testimonials_top = 0,
      viewport_height = 0;

    function scrollableSections() {

      if ($('.sa-overview__section__content__scrollable__images').length) {

        $sections = $('.sa-overview__section[data-id="design"] .sa-overview__section__content__scrollable, .sa-overview__section[data-id="print"] .sa-overview__section__content__scrollable');
        $testimonials = $('.sa-overview__testimonials');
        testimonials_top = $testimonials.offset().top;

        scroll_top = $(window).scrollTop() + $('.internal-sub-header').height();
        scroll_top = ($('.banner2023').length > 0) ? scroll_top + $('.banner2023').height() : scroll_top;
        viewport_height = ($(window).height() - $navigation.height() - $('.internal-sub-header').height());
        viewport_height = ($('.banner2023').length > 0) ? viewport_height - $('.banner2023').height() : viewport_height;

        $sections.each(function () {

          var
            $e = $(this),
            starts = $e.offset().top,
            ends = ($e.offset().top + $e.height() - viewport_height),
            $images = $e.find('.sa-overview__section__content__scrollable__images'),
            $images_items = $images.find('.sa-overview__section__content__scrollable__images__item'),
            $texts = $e.find('.sa-overview__section__content__scrollable__texts__item'),
            progress = 0,
            sub_progress = 0,
            visible_slide = 0,
            factor = 0;

          if (scroll_top >= starts) {

            // Scrolling to the end of section
            if (scroll_top < ends) {

              $images
                .css({
                  'width': $images.width()
                })
                .addClass('-fixed');
              $images.removeClass('-absolute');

            }

            else if (scroll_top >= ends && scroll_top < ends + viewport_height) {
              $images.addClass('-absolute');
            }

            else if (scroll_top >= testimonials_top) {
              $images.removeClass('-fixed');
            }

            else if (scroll_top < testimonials_top) {
              $images.addClass('-fixed');
              $images.removeClass('-absolute');
            }

          }
          else {
            $images
              .removeClass('-fixed')
              .removeClass('-absolute');
          }

          if (scroll_top >= starts - viewport_height * 0.5) {

            // Scrolling to the end of section
            if (scroll_top < ends) {

              progress = ($(window).scrollTop() - $e.offset().top - $navigation.height() + $(window).height()) / $e.height() * 100;
              visible_slide = Math.round(progress / (100 / $images_items.length));
              visible_slide = (progress < 42) ? 1 : visible_slide;

              // Calculate sub progress
              if ($images_items.length > 2) {

                if (visible_slide === 1 || visible_slide === $images_items.length)
                  factor = 12.5;
                else
                  factor = 25;

                if (visible_slide === 1)
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (factor / 100)) * 1.25;
                else if (visible_slide === 2)
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (16 / 100)) + 50;
                else if (visible_slide === $images_items.length)
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (factor / 100)) + 100;
                else
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (12.5 / 100)) + 50;

              }

              else if ($images_items.length === 2) {

                if (visible_slide === 1)
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (25 / 100)) * 1.25;
                else if (visible_slide === $images_items.length)
                  sub_progress = ((progress - (100 / $images_items.length) * visible_slide) / (25 / 100)) + 100;

              }

              if (sub_progress < 7)
                sub_progress = 0;
              else if (sub_progress > 93)
                sub_progress = 100;

              if (visible_slide === 1) {

                $images_items.css({
                  'opacity': 0
                });

                $images_items
                  .eq(visible_slide - 1)
                  .css({
                    'opacity': 1
                  })
                  .addClass('-show');

                $texts.removeClass('-visible');
                $texts
                  .eq(visible_slide - 1)
                  .addClass('-visible')
                  .addClass('-show');

              }

              else {

                $images_items.css({
                  'opacity': 0
                });

                $images_items
                  .eq(visible_slide - 1)
                  .css({
                    'opacity': 1
                  });

                $texts.removeClass('-visible');
                $texts
                  .eq(visible_slide - 1)
                  .addClass('-visible');

              }

            }

          }

          else {
            $images_items.css('opacity', 0).removeClass('-show');
            $texts.removeClass('-visible').removeClass('-show');
          }

        });

      }

    }

    scrollableSections();

    $(window).on('scroll.pxtrigger', function () {
      scrollableSections();
    });


  }


  // Open video in modal window
  $('.js-watch-video').each(function () {

    var
      $e = $(this),
      $target = $e.next('.video-target'),
      href = $target.attr('href');

    $e.click(function (evt) {
      evt.preventDefault();
      $.magnificPopup.open({

        type: 'iframe',
        items: {
          src: href
        },
        iframe: {
          markup:
            '<div class="mfp-iframe-scaler">' +
            '<div class="mfp-title"></div>' +
            '<div class="mfp-close"></div>' +
            '<iframe class="mfp-iframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>' +
            '</div>'
        },
        fixedContentPos: true,
        fixedBgPos: true,

        overflowY: 'auto',

        closeBtnInside: true,
        preloader: false,

        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in'

      });
    });

  });
}
