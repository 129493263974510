import $ from 'jquery'

export function ajaxFormsUpdateDocument() {

  $('.ajax_form_submit, .btn-ajax-submit').closest("form").on('keypress', function (e) {
    //if enter pressed
    if (e.which === 13) {
      $(this).find('.ajax_form_submit').click();
    }
  });

  $('.ajax_form_submit, .btn-ajax-submit').on('click', function (event) {
    var el = $(this);
    var form = el.closest("form");

    event.preventDefault();

    if (form.data('px-submitting'))
      return;

    form.data('px-submitting', true);

    var url = form.attr('action');
    var data = form.serialize();
    var method = form.attr('method');
    var form_type = form.data('form_type');
    var form_feedback = form.find('.form-feedback');
    var url_to_redirect = el.data('url_to_redirect');

    if (form_type === 'contact') {
      el.html('Sending...');
    }
    if (form_type === 'convention-sign-up') {
      el.html('Submitting...')
    }

    form_feedback.removeClass('success').removeClass('error').html('&nbsp;');
    form_feedback.css({ visibility: 'hidden' });
    form.find('input, textarea').removeClass('error');
    $('.feedback.error').addClass('hidden');

    $.ajax({
      url: url,
      method: method,
      data: data,
      dataType: 'json',
      error: function (resp) {
        form.data('px-submitting', false);
      },
      success: function (resp) {

        if (resp.status >= 100) {
          form.data('px-submitting', false);

          if (resp.message) {
            form_feedback
              .addClass('error')
              .html(resp.message)
              .css({ opacity: 0 })
              .css({ visibility: 'visible' })
              .animate({
                opacity: 1
              }, 500);
          }

          for (var i in resp.data.error_fields) {
            form.find('input[name="' + resp.data.error_fields[i] + '"], textarea[name="' + resp.data.error_fields[i] + '"]').addClass('error');

            if (form_type === 'contact') {
              el.html("Send message");
            }
            if (form_type === 'convention-sign-up') {
              el.html("Sign up");
            }
          }

          if (101 === resp.status)
            $('.feedback.error').removeClass('hidden');

        } else {

          // Make sure trial agreement is accepted on trial_download_sign_up page.
          if (url_to_redirect) {
            if (form_type === "trial_download_sign_up" && !$('#px_pws_trial_download_agree_terms_and_privacy:checked').length > 0) {
              $('.feedback.error').removeClass('hidden');
              return false;
            }
            // Push to the GTM dataLayer
            if (form_type === 'trial_download_sign_up')
              localStorage.setItem('FP-email', $('#email').val());
            document.location = url_to_redirect;
          }
          else if (form_type === "contact") {
            form.data('px-submitting', false);

            $(window).scrollTop(0);

            $('.contact-title span')
              .html("Thank you!")
              .css({ opacity: 0 })
              .animate({ opacity: 1 }, 500);
            $('.contact-content')
              .html("We have received your message and will respond to you very soon.")
              .addClass('confirmation')
              .css({ opacity: 0 })
              .animate({ opacity: 1 }, 500);
          }
          else {
            // Push to the GTM dataLayer
            if (form_type === 'mobile_trial_download_sign_up') {
              var event = {
                event: 'SATrialConfirmation',
                email: $('#email').val()
              };
              window.dataLayer.push(event);
            }

            form.data('px-submitting', false);

            form_feedback
              .addClass('success')
              .html(resp.message)
              .css({ opacity: 0 })
              .css({ visibility: 'visible' })
              .animate({
                opacity: 1
              }, 500);
          }
        }
      }
    });
  });

  // PSS Beta Signup Form
  $('form.subscribe-form').on('submit', function (e) {
    e.preventDefault();

    var $form = $(e.target);

    $form.removeClass('is-error').addClass('is-submitting');

    $.ajax({
      url: $form.attr('action'),
      type: 'POST',
      data: $form.serialize(),
      dataType: 'json',
      success: function (response) {
        if (response.error)
          $form.removeClass('is-submitting').addClass('is-error');
        else
          $form.removeClass('is-submitting').addClass('is-success');
      }
    });
  });

  // Any ajax form


  // Custom validation for our forms
  $('form.px-validate').on('submit', function (e) {
    var $form = $(this).removeClass('px-validate-failed'),
      $el,
      isValid,
      validationPassed = true;

    // Remove errors from previous form submission
    $form.find('.px-required.error, .styled.error').removeClass('error');

    // Validate
    $form.find('.px-required').each(function (i, el) {
      $el = $(el);

      isValid = $el.val() && ('' !== $el.val().replace(/\s+/g, ''));

      if ('email' === $el.attr('type'))
        isValid = isValid && /^.+?@.+?\..+/.test($el.val());

      if (!isValid) {
        if ($el.is('select')
          && $el.parent().is('.styled')) {
          $el.parent().addClass('error');
        }
        else
          $el.addClass('error');

        validationPassed = false;
      }
    });

    // Interrupt form submission in case we have validation errors
    if (!validationPassed) {
      $form.addClass('px-validate-failed');
      e.preventDefault();
      e.stopPropagation();
    }

    return validationPassed;
  });

  // General forms submission
  $('form.ajax_form').on('submit', function (e) {
    e.preventDefault();

    var $form = $(e.target),
      $submit = $form.find('.support-contact-submit'),
      fieldName;

    // Do not submit form if it is being submitted
    if ($form.data('px-submitting'))
      return;

    // Do not submit form if it has failed validation
    if ($form.is('.px-validate-failed'))
      return;

    // Remove errors from previous form submission
    $form.data('px-submitting', true).removeClass('is-error').removeClass('is-sending-error').addClass('is-submitting');
    $form.find('.px-required.error, .styled.error').removeClass('error');

    // Set the loading state
    if ($submit.data('sending-value')) {
      if (!$submit.data('normal-state-value'))
        $submit.data('normal-state-value', $submit.val());

      $submit.val($submit.data('sending-value'));
    }

    // Send request
    $.ajax({
      url: $form.attr('action'),
      type: 'POST',
      data: $form.serialize(),
      dataType: 'json',
      success: function (response) {
        $submit.val($submit.data('normal-state-value'));

        if (response.status >= 100) {
          $form.data('px-submitting', false).removeClass('is-submitting').addClass('is-error');

          if (('undefined' !== typeof response.data)
            && 'undefined' !== typeof response.data.failed_fields) {
            for (var i in response.data.failed_fields) {
              fieldName = response.data.failed_fields[i];
              var $el = $form.find('[name=' + fieldName + ']');

              if ($el.is('select')
                && $el.parent().is('.styled')) {
                $el.parent().addClass('error');
              }
              else
                $el.addClass('error');
            }
          }
          else
            $form.addClass('is-sending-error');
        }
        else
          $form.data('px-submitting', false).removeClass('is-submitting').addClass('is-success');
      },
      error: function () {
        $submit.val($submit.data('normal-state-value'));
        $form.data('px-submitting', false).removeClass('is-submitting').addClass('is-error is-sending-error');
      }
    });
  });

}
