import $ from 'jquery'

export function setSentryCookie() {

  function setCookie(name, value, days) {
    var date, expires;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  var
    path = window.location.pathname.split('/'),
    value = path[3],
    $title = $('#cookie-message');

  // Remove cookie
  if (value === 'off') {
    setCookie('pws-run-sentry', 'no', -1);
    $title.text('Sentry is turned off');
  }

  // Set cookie
  // eslint-disable-next-line
  else if (/^[-\+]?\d+/.test(value)) {
    // eslint-disable-next-line
    var days = (value == 1) ? 'day' : 'days';
    setCookie('pws-run-sentry', 'yes', value);
    $title.text('Sentry is running for ' + value + ' ' + days);
  }

}
