import $ from 'jquery'
import { navigate } from "gatsby"

export function post() {
  let url;


  // Close the post using the cross
  if ($('.post__cross').length > 0) {

    var $cross = $('.post__cross');

    $cross.on('click.pxtrigger', function () {

      if (window.history.length <= 2)
        navigate('/blog/');
      else
        window.history.back();

    });

  }


  // Links to Facebook and Twitter
  if ($('[data-id="facebook"]').length > 0 && $('[data-id="twitter"]').length > 0) {

    url = $('[rel="canonical"]').attr('href');

    $('[data-id="facebook"], [data-id="twitter"]').each(function () {

      var
        $e = $(this),
        href = $e.attr('href');

      $e.attr('href', href + encodeURIComponent(url));

    });

  }


  // Link to Mail Client
  if ($('[data-id="email"]').length > 0) {

    url = $('[rel="canonical"]').attr('href');

    $('[data-id="email"]').attr(
      'href',
      `mailto:
        ?subject=${$('title').text()}
&body=Hello,%0D%0A%0D%0A
I found an article that you may enjoy. They didn't have an option to send this to you via homing-pigeon, so I thought I'd email it to you instead.%0D%0A%0D%0A
You can read the article here:%0D%0A
${encodeURIComponent(url)}`
    );

  }


  // Connect social widgets
  $('script[id$="-oembed-script"]').remove();

  $('[data-oembed]').each(function () {

    var
      $e = $(this),
      provider = $e.attr('data-oembed-provider');

    if (provider === 'Twitter' && $('#twitter-oembed-script').length === 0)
      $('body').append(`<script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8" id="twitter-oembed-script"></script>`);

    else if (provider === 'Facebook' && $('#facebook-oembed-script').length === 0)
      $('body').append(`<script async="1" defer="1" crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v6.0" id=
		"facebook-oembed-script"></script>` );

    else if (provider === 'Instagram' && $('#instagram-oembed-script').length === 0)
      $('body').append(`<script async="" src="//www.instagram.com/embed.js" id="instagram-oembed-script"></script>`);

  });


  // PSS slideshow embeding
  $('.post__content').find('pre').each(function () {
    var
      $e = $(this),
      text = $e.text();

    $e.html(text).addClass('-show');
  });


}
