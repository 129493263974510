import $ from 'jquery'

export function checkout () {
  const pxRptElement = document.querySelector('px-rpt');

  function redirectTo(url, loaderText){
    $('.loading-container__text').html(loaderText);
    $('#end-waypoint').addClass('-hidden');
    $('.loading-overlay').addClass('-visible');
    setTimeout(function(){
      $('.loading-container').addClass('-visible');
      setTimeout(function(){
        window.location.href = url;
      }, 900);
    }, 400);
  }

  function redirectToDashboard(priceId, experiment, productFamilyId, period, productName, productFamily) {
    let discountCode = '';

    experiment = (experiment === 'yes') ? '&experiment=yes' : '';
    productFamilyId = (productFamilyId) ? '&product=' + productFamilyId : '';

    let checkoutURI = '&state=' + encodeURIComponent('/dashboard?a=checkout'  + productFamilyId + experiment + '&price=' + priceId + period + productName + productFamily + discountCode);

    if (productFamily.toUpperCase() === '&PRODUCTFAMILY=BUNDLE') {
      checkoutURI = '&state=' + encodeURIComponent('/dashboard?action=upgradetobundle');
    }

    redirectTo(
      window.px_config.pxDashboard.url.auth + checkoutURI,
      'Loading... <span>Please wait...</span>'
    );
  }

  // Listen for the component being fully initialized
  pxRptElement.addEventListener('px-pricing-initialised', (event) => {
    pxRptElement.shadowRoot.append(
      Object.assign(document.createElement('style'), {
        innerText : `
          .period-selector__label::first-letter {
            text-transform: uppercase;
          }
        `
      })
    )
  });

  // Listen for "Buy Now" button click
  pxRptElement.addEventListener('px-pricing-buy-now-click', (event) => {
    const auth_token = pxRptElement.getAttribute('jwt');
    const priceId = event.detail.id;
    const experiment = 'no';
    const productFamilyId = event.detail.productFamily;
    const period = event.detail.period;
    const productName = event.detail.productName;

    const periodURI = (period) ? '&period=' + encodeURIComponent(period) : '';
    const productNameURI = (productName) ? '&productName=' + encodeURIComponent(productName) : '';
    const productFamilyURI = (productFamilyId) ? '&productFamily=' + encodeURIComponent(productFamilyId) : '';

    if (!auth_token) {
      redirectToDashboard(priceId, experiment, productFamilyId, periodURI, productNameURI, productFamilyURI);
    }
  });

  // Listen for successful checkout
  pxRptElement.addEventListener('px-pricing-checkout-success', (event) => {
    const e = event.detail;
    const redirectLink = window.px_config.account_url +
      '/dashboard' +
      '?priceId=' + e.id +
      '&discountCode=undefined' +
      '&payment-success=true' +
      '&period=' + encodeURIComponent(e.period) +
      '&productName=' + encodeURIComponent(e.productName) +
      '&productFamily=' + encodeURIComponent(e.productFamily);

    window.location.href = redirectLink;
  });

  // Listen for successful upgrade
  pxRptElement.addEventListener('px-pricing-upgrade-success', (event) => {
    const e = event.detail;
    const redirectLink = window.px_config.account_url +
      '/dashboard' +
      '?priceId=' + e.id +
      '&discountCode=undefined' +
      '&payment-success=true' +
      '&period=' + encodeURIComponent(e.period) +
      '&productName=' + encodeURIComponent(e.productName) +
      '&productFamily=' + encodeURIComponent(e.productFamily);

    window.location.href = redirectLink;
  });
}
