import $ from 'jquery'

export function testimonials () {
  if (window.intervalIds) {
    window.intervalIds.forEach(clearInterval);
  }
  window.intervalIds = [];

  let switchTestimonialsInterval;
  let animationTimeouts = new Map();

	// Testimonials
	var
		$items = $('.sa2019__testimonials__item'),
    $avatars = $items.children('.sa2019__testimonials__item__avatar'),
    $texts = $items.children('.sa2019__testimonials__item__text'),
    $names = $items.children('.sa2019__testimonials__item__name'),
    $navigation = $('.sa2019__testimonials__navigation'),
    $navigation_items = $navigation.children('.sa2019__testimonials__navigation__item');

	// Switch testimonials by timer
	if( $items.length > 1 ){

		function switchTestimonials( action ){

			if( action === 'reset' ){
				clearInterval( switchTestimonialsInterval );
				switchTestimonials();
			}

			else{

				switchTestimonialsInterval = setInterval(function(){

          var
            $active = $navigation.children('.sa2019__testimonials__navigation__item.-active'),
            $next = ( $active.next('.sa2019__testimonials__navigation__item').length > 0 ) ? $active.next('.sa2019__testimonials__navigation__item') : $('.sa2019__testimonials__navigation__item').eq(0),
            $next_item = $items.eq( $next.index() ),
              $next_avatar = $next_item.children('.sa2019__testimonials__item__avatar'),
              $next_text = $next_item.children('.sa2019__testimonials__item__text'),
              $next_name = $next_item.children('.sa2019__testimonials__item__name');

          $navigation_items.removeClass('-active');
          $next.addClass('-active');

          $names.removeClass('-show');

          animationTimeouts.set('interval:texts', setTimeout(function(){
            animationTimeouts.delete('interval:texts');
            $texts.removeClass('-show');
          }, 150));

          animationTimeouts.set('interval:avatars', setTimeout(function(){
            animationTimeouts.delete('interval:avatars');
            $avatars.removeClass('-show');
          }, 300));

          animationTimeouts.set('interval:next', setTimeout(function(){
            animationTimeouts.delete('interval:next');

            $items.removeClass('-active');
            $next_item.addClass('-active');

            $next_avatar.addClass('-show');

            animationTimeouts.set('interval:next-text', setTimeout(function(){
              animationTimeouts.delete('interval:next-text');
              $next_text.addClass('-show');
            }, 150));

            animationTimeouts.set('interval:next-name', setTimeout(function(){
              animationTimeouts.delete('interval:next-name');
              $next_name.addClass('-show');
            }, 300));

          }, 600));
				}, 5000);
        window.intervalIds.push(switchTestimonialsInterval);

			}

		}
		switchTestimonials();

		// Switch testimonials on click
		$navigation_items.each(function(){

			var
				$e = $(this),
				$e_item = $items.eq( $e.index() ),
					$e_avatar = $e_item.children('.sa2019__testimonials__item__avatar'),
					$e_text = $e_item.children('.sa2019__testimonials__item__text'),
					$e_name = $e_item.children('.sa2019__testimonials__item__name');

			$e.click(function(){

				if( !$e.hasClass('-active') ){

					switchTestimonials( 'reset' );

					$navigation_items.removeClass('-active');
					$e.addClass('-active');

					$names.removeClass('-show');

          animationTimeouts.set('click:texts', setTimeout(function(){
            animationTimeouts.delete('click:texts');
						$texts.removeClass('-show');
					}, 150));

          animationTimeouts.set('click:avatars', setTimeout(function(){
            animationTimeouts.delete('click:avatars');
						$avatars.removeClass('-show');
					}, 300));

          animationTimeouts.set('click:e_item', setTimeout(function(){
            animationTimeouts.delete('click:e_item');

						$items.removeClass('-active');
						$e_item.addClass('-active');

						$e_avatar.addClass('-show');

            animationTimeouts.set('click:e_text', setTimeout(function(){
              animationTimeouts.delete('click:e_text');
							$e_text.addClass('-show');
						}, 150));

            animationTimeouts.set('click:e_name', setTimeout(function(){
              animationTimeouts.delete('click:e_name');
							$e_name.addClass('-show');
						}, 300));

					}, 600));

				}

			});

		});

	}
}
