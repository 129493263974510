import $ from 'jquery'

export function smartalbums2022 () {
  let animationTimeouts = new Map();

	// Hero animation
	var
		$hero = $('.sa2020__hero');

		// Show title
		$hero.addClass('-show-title');

		// Show subtitle
    animationTimeouts.set('show-subtitle', setTimeout(function(){
      animationTimeouts.delete('show-subtitle');
			$hero.addClass('-show-subtitle');
		}, 300));

		// Show description
    animationTimeouts.set('show-description', setTimeout(function(){
      animationTimeouts.delete('show-description');
			$hero.addClass('-show-description');
		}, 600));

		// Show description
    animationTimeouts.set('show-mouse', setTimeout(function(){
      animationTimeouts.delete('show-mouse');
			$hero.addClass('-show-mouse');
		}, 900));


	// Do more section's animation
  var
      $more,
      top = 0,
      scroll_top = 0,
      timeout = 250;

	function showMoreOnScroll(){

    if( $('.sa2020__more').length ){

      $more = $('.sa2020__more');
      top = $more.offset().top;
      scroll_top = $(window).scrollTop();
      timeout = 250;

      if( scroll_top >= top - $(window).height() / 1.45 && scroll_top > 0 && !$more.hasClass('-show') ){

        $more.addClass('-show');

        animationTimeouts.set('show-more-on-scroll', setTimeout(function(){
          animationTimeouts.delete('show-more-on-scroll');

          // Show title
          $more.addClass('-show-title');

          // Show text
          animationTimeouts.set('show-text', setTimeout(function(){
            animationTimeouts.delete('show-text');
            $more.addClass('-show-text');
          }, 300));

          // Show button
          animationTimeouts.set('show-button', setTimeout(function(){
            animationTimeouts.delete('show-button');
            $more.addClass('-show-button');
          }, 600));

        }, timeout));

      }

		}

	}

	showMoreOnScroll();

	$(window).on('scroll.pxtrigger', function() {

		showMoreOnScroll();

	});
}
