import $ from 'jquery'
import { navigate } from "gatsby"
import { isBrowser } from "../helpers/general-helper";

export function smartalbumsVideoTutorials() {

  // Redirect to user's language SA Tutorials page if any
  let browserLang = $('html').attr('lang');
  let redirect_link = '';
  if (isBrowser() && browserLang && (browserLang === 'pt' || browserLang === 'ru' || browserLang === 'es')) {
    redirect_link = browserLang === 'ru' ? 'ru' : browserLang + '-' + browserLang;
    window.history.replaceState('/tutorials-smartalbums', '', '/support');
    navigate('/' + redirect_link + '/tutorials-smartalbums');
  }

  // Tabs switcher
  // tabs - tabs selector on it device
  // contents - contents selector on it device
  // secondary_tabs - tabs selector on another device (for example, on mobile if we on desktop)
  // secondary_contents - contents selector on another device
  function switchTabs(
    tabs, contents, trigger, active_class,
    secondary_tabs, secondary_contents, secondary_active_class
  ) {

    var
      $tabs = $(tabs),
      $contents = $(contents),
      $s_tabs = $(secondary_tabs),
      $s_contents = $(secondary_contents),
      index_correction = 0;

    $contents.hide();

    $tabs.each(function () {

      var
        $e = $(this),
        $trigger = $e.children(trigger),
        cat = $trigger.data('cat'),
        $e_content = $(contents.slice(0, contents.indexOf('[')) + '[data-cat="' + cat + '"]'),
        $e_s_content = $(secondary_contents.slice(0, secondary_contents.indexOf('[')) + '[data-cat="' + cat + '"]');

      if ($e.hasClass(active_class)) {
        $e_content.show();
        $e_s_content.show();
      }

      $trigger.click(function () {

        index_correction = (tabs === '.video-menu .part') ? -1 : 1;

        $tabs.removeClass(active_class);
        $s_tabs.removeClass(secondary_active_class);

        $e.addClass(active_class);
        $s_tabs.eq($e.index() - index_correction).addClass(secondary_active_class);

        $contents.hide();
        $s_contents.hide();

        $e_content.show();
        $e_s_content.show();

      });

    });

  }


  //Switch tab's contents on desktop
  switchTabs(
    '.video-menu .part', '.video-list .video-list__container__group[data-cat]', 'button[data-cat]', 'selected',
    '.filter > li', '.video-list-mobile > div[data-cat]', 'active'
  );


  //Switch tab's contents on mobile
  switchTabs(
    '.filter > li:not(.title)', '.video-list-mobile > div[data-cat]', 'span[data-cat]', 'active',
    '.video-menu .part', '.video-list .video-list__container__group[data-cat]', 'selected',
  );
}
