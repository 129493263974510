import Cookies from 'js-cookie'

export function setUTMCookie() {

  let domain = window.location.host.split('.').reverse();
  domain = '.' + domain[1] + '.' + domain[0];

  // Read the UTM-params
  let
    utm_query = decodeURIComponent(window.location.search.substring(1)),
    utm_variables = utm_query.split('&');

  utm_variables.forEach(function (variable) {

    var
      name = variable.split('=')[0],
      value = variable.split('=')[1];

    // Set up the cookie
    if (name.slice(0, 4) === 'utm_')
      Cookies.set('px_' + name, value, { domain: domain, expires: 365 });

  });

}
